<template>
  <div>
    <div style="margin-bottom: 24px">
      <a-card :bordered="false">
        <DescriptionList
          title="客户信息"
          size="large"
          style="margin-bottom: 32px"
        >
          <div style="margin-top: 16px">
            <Description term="店铺ID"
              ><label>{{ detail.storeId }}</label></Description
            >
            <Description term="店铺名称"
              ><label>{{ detail.storeName }}</label></Description
            >
            <Description term="客户ID"
              ><label>{{ fintenantCode }}</label></Description
            >
            <Description term="客户名称"
              ><label>{{ fintenantName }}</label></Description
            >
            <Description term="品牌名称"
              ><label>{{ detail.brandName }}</label></Description
            >
            <Description term="签约档口"
              ><label>{{ detail.signKitchen }}</label></Description
            >
            <Description term="当前账单合同"
              ><a @click="contractDetail(detail)">{{
                detail.billContractCode
              }}</a></Description
            >
          </div>
        </DescriptionList>
        <DescriptionList title="账单明细" size="large">
          <div style="margin-top: 16px">
            <Description term="账单编号"
              ><label>{{ detail.code }}</label></Description
            >
            <Description term="账单类型"
              ><label>{{ detail.type }}</label></Description
            >
            <Description term="账单周期"
              ><label
                >{{
                  detail.cycleStartDateLg
                    ? moment(detail.cycleStartDateLg).format("YYYY-MM-DD")
                    : ""
                }}—{{
                  detail.cycleEndDateLg
                    ? moment(detail.cycleEndDateLg).format("YYYY-MM-DD")
                    : ""
                }}</label
              ></Description
            >
            <Description term="账单金额"
              ><label>{{ detail.billAmount }}</label></Description
            >
            <Description term="缴费结束时间"
              ><label>{{
                detail.payCycleEndDateLg
                  ? moment(detail.payCycleEndDateLg).format("YYYY-MM-DD")
                  : ""
              }}</label></Description
            >
            <Description term="拖欠天数"
              ><label>{{ detail.arrearsDay }}</label></Description
            >
            <Description term="滞纳金"
              ><label>{{ detail.lateFeeY }}</label></Description
            >
            <Description term="需缴费金额"
              ><label>{{ detail.needPayment }}</label></Description
            >
            <Description term="账单是否已确认"
              ><label>{{
                changeBillStatus(detail.isBillConfirm)
              }}</label></Description
            >
          </div>
        </DescriptionList>
      </a-card>
    </div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <div>
        <div>
          <label class="listTitle" style="margin-bottom: 24px">支付信息</label>
        </div>
        <a-table
          rowKey="id"
          :pagination="paginationPay"
          :columns="payColumns"
          :dataSource="payDetail"
          :scroll="{ x: 1300 }"
          @change="changePageSizePay"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <span slot="payModel" slot-scope="text, record">
            {{ changepayModel(record.payModel) }}
          </span>
          <span slot="payChannel" slot-scope="text, record">
            {{ changepayChannel(record.payChannel) }}
          </span>
          <span
            slot="payPoofImgList"
            slot-scope="text, record"
            class="blueColor"
          >
            <template>
              <a @click="payPoofImgListPer(record)">{{
                record.payPoofImgList ? "支付凭证图片" : " "
              }}</a
              >&nbsp;&nbsp;
              <a-modal
                v-if="record.payPoofImgList"
                :visible="record.previewpayPoofImgList"
                :footer="null"
                @cancel="handleCancel(record)"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="record.payPoofImgList[0]"
                />
              </a-modal>
            </template>
          </span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <template v-if="record.payChannel == 3">
              <!--              <a @click="handleDetail(record)">查看凭证</a>&nbsp;&nbsp;-->
              <a
                v-if="record.isArrival == '未到账' && confHasRight === true"
                @click="showConfirm(record)"
                >确认到账</a
              >
            </template>
          </span>
        </a-table>
      </div>
      <a-modal
        title="确认到账"
        :width="640"
        :visible="confirmvisible"
        :confirmLoading="confirmLoading"
        destroyOnClose
        @ok="handleconfirm"
        @cancel="handleCancel"
      >
        <a-spin :spinning="confirmLoading">
          <a-form :form="form">
            <a-form-item
              label="支付订单号"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'bankPayNo',
                  {
                    rules: [{ required: false, message: '请输入' }],
                    initialValue: record.payOrderCode
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="支付金额"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'payAmount',
                  {
                    rules: [{ required: false, message: '请输入' }],
                    initialValue: record.payAmount
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="支付方式"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'payModel',
                  {
                    rules: [{ required: false, message: '请选择!' }],
                    initialValue: record.payModel
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in payModelList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="支付渠道"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-select
                v-decorator="[
                  'payChannel',
                  {
                    rules: [{ required: false, message: '请选择！' }],
                    initialValue: record.payChannel
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in payChannelList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
    </a-card>
    <a-card :bordered="false">
      <div style="display: inline-block; margin-bottom: 24px">
        <label class="listTitle">费用明细</label>
        <!--        <a-form layout="inline" style="margin-bottom: 32px; text-align: right">-->
        <!--          <a-button icon="plus" type="primary" @click="addBill"-->
        <!--            >新增费用</a-button-->
        <!--          >-->
        <!--        </a-form>-->
        <!--      </div>-->
      </div>
      <a-table
        rowKey="id"
        :pagination="paginationBail"
        :columns="detailColumns"
        :dataSource="data"
        :scroll="{ x: 800 }"
        @change="changePageSizeBail"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="typeName" slot-scope="text, record">
          {{ record.typeName }}
        </span>
        <!--        <span slot="action" slot-scope="text, record" class="blueColor">-->
        <!--          <template>-->
        <!--            <a @click="handleDetails(record)">查看</a>&nbsp;&nbsp;-->
        <!--            <a @click="handleEdit(record)">编辑</a>&nbsp;&nbsp;-->
        <!--            <a @click="handleActive">操作日志</a>-->
        <!--          </template>-->
        <!--        </span>-->
      </a-table>
    </a-card>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import moment from "moment";
  
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
import { mapGetters } from "vuex";
import { hasPermissions } from "../../../utils/auth";
import { FacilityActions, OrganizationActions } from "../../../utils/actions";

export default {
  name: "financeBailDetail",
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      financeBailDetailId: this.$route.query.financeBailDetailId,
      finbillType: this.$route.query.finbillType,
      fintenantCode: this.$route.query.fintenantCode,
      fintenantName: this.$route.query.fintenantName,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      BillConfirmList: [
        { id: 1, name: "待确认" },
        { id: 2, name: "系统自动确认" },
        { id: 3, name: "商户手动确认" },
        { id: 4, name: "标记延缓确认" }
      ],
      confirmvisible: false,
      confirmLoading: false,
      previewpayPoofImgList: false,
      form: this.$form.createForm(this),
      data: [],
      paginationPay: defaultPagination(() => {}),
      paginationBail: defaultPagination(() => {}),
      //费用类型(非经营费用账单)
      costTypeList: [
        { id: 1, name: "水费" },
        { id: 2, name: "电费" },
        { id: 3, name: "燃气" },
        { id: 4, name: "房租" },
        { id: 5, name: "出租租金押金" },
        { id: 6, name: "入会费" },
        { id: 7, name: "燃气押金" },
        { id: 8, name: "质量保证金" },
        { id: 9, name: "商户减免" },
        { id: 10, name: "商户扣除" },
        { id: 11, name: "改造费" },
        { id: 12, name: "增容费" },
        { id: 13, name: "燃气改造费" },
        { id: 14, name: "其他费用" },
        { id: 15, name: "首期款定金" }
      ],
      //费用类型(经营费用账单)
      costTypeList_Y: [
        { id: 2, name: "公摊电费" },
        { id: 1, name: "公摊水费" },
        { id: 3, name: "油烟清洗费" },
        { id: 4, name: "消杀费" },
        { id: 5, name: "宽带网络费" },
        { id: 6, name: "管道疏通费" },
        { id: 7, name: "垃圾清运费" },
        { id: 8, name: "堂食管理费" },
        { id: 9, name: "物业费" },
        { id: 10, name: "保洁费" },
        { id: 11, name: "电梯维保费" },
        { id: 12, name: "固定费用" },
        { id: 13, name: "取暖费" }
      ],
      //费用类型(经营费用账单末期>0)
      costTypeList_N_last_Y: [
        { id: 1, name: "临时房租补贴" },
        { id: 2, name: "广告费" },
        { id: 3, name: "燃气充值卡" },
        { id: 4, name: "水卡充值" },
        { id: 5, name: "电卡充值" },
        { id: 6, name: "仓库费" },
        { id: 7, name: "工服费" },
        { id: 8, name: "违约金" },
        { id: 9, name: "修理费" },
        { id: 10, name: "代运营费" },
        { id: 11, name: "经营费用减免" },
        { id: 12, name: "其他费用" }
      ],
      //费用类型(经营费用账单末期<0)
      costTypeList_N_last_N: [
        { id: 1, name: "水费" },
        { id: 2, name: "电费" },
        { id: 3, name: "燃气" }
      ],
      //支付方式
      payModelList: [
        { id: 1, name: "线上支付" },
        { id: 2, name: "线下支付" },
        { id: 3, name: "转账" },
        { id: 4, name: "定金支付" },
        { id: 5, name: "押金抵扣" },
        { id: 6, name: "余额支付" }
      ],
      //支付渠道
      payChannelList: [
        { id: 1, name: "支付宝" },
        { id: 2, name: "微信" },
        { id: 3, name: "线下转账" },
        { id: 4, name: "POS机" },
        { id: 5, name: "二维码支付" },
        { id: 6, name: "POS银行卡" },
        { id: 7, name: "POS扫一扫" },
        { id: 8, name: "账户余额" }
      ],
      //表头
      detailColumns: [
        {
          title: "费用ID",
          dataIndex: "code",
          key: "code",
          fixed: "left"
        },
        {
          title: "费用类型",
          dataIndex: "typeName",
          key: "typeName",
          scopedSlots: { customRender: "typeName" }
        },
        // {
        //   title: "是否公摊",
        //   dataIndex: "isPublic",
        //   key: "isPublic"
        // },
        {
          title: "初期读数",
          dataIndex: "initialStageNum",
          key: "initialStageNum"
        },
        {
          title: "末期读数",
          dataIndex: "lastStageNum",
          key: "lastStageNum"
        },
        {
          title: "使用量",
          dataIndex: "usage",
          key: "usage"
        },
        {
          title: "单价/总价",
          dataIndex: "price",
          key: "price"
        },
        {
          title: "金额",
          dataIndex: "totalAmount",
          key: "totalAmount"
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime"
        },
        {
          title: "创建人",
          dataIndex: "creator",
          key: "creator",
          fixed: "right",
          width: 150
        }
      ],
      //表头
      payColumns: [
        {
          title: "支付金额",
          dataIndex: "payAmount",
          key: "payAmount"
        },
        {
          title: "支付方式",
          // dataIndex: "payModel",
          key: "payModel",
          scopedSlots: { customRender: "payModel" }
        },
        {
          title: "支付渠道",
          // dataIndex: "payChannel",
          key: "payChannel",
          scopedSlots: { customRender: "payChannel" }
        },
        {
          title: "支付状态",
          dataIndex: "payStatus",
          key: "payStatus"
        },
        {
          title: "支付时间",
          dataIndex: "payTime",
          key: "payTime"
        },
        {
          title: "转账日期",
          dataIndex: "transferTimeLg",
          key: "transferTimeLg"
        },
        {
          title: "到账状态",
          dataIndex: "isArrival",
          key: "isArrival"
        },
        {
          title: "支付确认时间",
          dataIndex: "payConfirmTime",
          key: "payConfirmTime"
        },
        {
          title: "支付确认人",
          dataIndex: "paymentConfirmed",
          key: "paymentConfirmed"
        },
        {
          title: "支付凭证图片",
          // dataIndex: "payPoofImgList",
          key: "payPoofImgList",
          scopedSlots: { customRender: "payPoofImgList" }
        },
        {
          title: "操作",
          // dataIndex: "action",
          scopedSlots: { customRender: "action" },
          key: "action",
          fixed: "right",
          width: 180
        }
      ],
      payDetail: [],
      detail: "",
      record: ""
    };
  },
  created() {
    this.init();
    this.payTenantList();
    this.billCostList();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/finance/financeBail"
      });
    });
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    confHasRight() {
      return hasPermissions(
        [OrganizationActions.BILL_READ],
        this.authenticatedUser.actions
      );
    }
  },
  methods: {
    changeBillStatus(val) {
      let name = "";
      if (val) {
        this.BillConfirmList.forEach(item => {
          if (val == item.id) {
            name = item.name;
          }
        });
      }
      return name;
    },
    moment,
    payPoofImgListPer(val) {
      val.previewpayPoofImgList = true;
    },
    init() {
      axios({
        url:   this.$baseUrl + "/bill/query",
        method: "GET",
        params: {
          id: this.financeBailDetailId
        }
      }).then(res => {
        if (res.data.obj) {
          res.data.obj.type = this.billType(res.data.obj.type);
          this.detail = res.data.obj;
        }
      });
    },
    changepayModel(val) {
      let name = "";
      if (val) {
        this.payModelList.forEach(item => {
          if (item.id == val) {
            name = item.name;
          }
        });
        return name;
      }
    },
    changepayChannel(val) {
      let name = "";
      if (val) {
        this.payChannelList.forEach(item => {
          if (item.id == val) {
            name = item.name;
          }
        });
        return name;
      }
    },
    billType(val) {
      // alert(val);
      switch (val) {
        case 1:
          return "首期款账单";
        case 2:
          return "改造费用账单";
        case 3:
          return "退租结算账单";
        case 4:
          return "房租账单";
        case 5:
          return "经营费用账单";
        default:
          "无";
      }
      // return ;
    },
    //支付信息
    payTenantList(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/bill/pay/tenant/list",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          billId: this.financeBailDetailId
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            this.$set(
              item,
              "payConfirmTime",
              item.payConfirmDateLg
                ? moment(item.payConfirmDateLg).format("YYYY-MM-DD HH:MM:SS")
                : ""
            );
            this.$set(
              item,
              "payTime",
              item.payTimeLg
                ? moment(item.payTimeLg).format("YYYY-MM-DD HH:MM:SS")
                : ""
            );
            this.$set(
              item,
              "transferTimeLg",
              item.transferTimeLg
                ? moment(item.transferTimeLg).format("YYYY-MM-DD")
                : ""
            );
            this.$set(item, "previewpayPoofImgList", false);
            item.isArrival =
              item.isArrival == "N"
                ? "未到账"
                : item.isArrival == "Y"
                ? "已到账"
                : item.isArrival == "P"
                ? "部分到账"
                : "";
            item.payStatus =
              item.payStatus == 1
                ? "未支付"
                : [item.payStatus == 2 ? "部分支付" : "已支付"];
          });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.paginationPay = pagination;
          this.payDetail = res.data.rows;
        }
      });
    },
    changePageSizePay(val) {
      this.payTenantList(val.current, val.pageSize);
    },
    //合同详情
    contractDetail(val) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: val.contractId }
      });
    },
    //费用明细
    billCostList(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/bill/cost/queryList",
        method: "GET",
        params: {
          billId: this.financeBailDetailId
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            if (this.finbillType == "经营费用账单") {
              if (item.isPublic == "Y") {
                this.costTypeList_Y.forEach(val => {
                  if (val.id == item.type) {
                    this.$set(item, "typeName", val.name);
                  }
                });
              } else {
                if (item.lastStageNum == "0") {
                  this.costTypeList_N_last_Y.forEach(val => {
                    if (val.id == item.type) {
                      this.$set(item, "typeName", val.name);
                    }
                  });
                } else {
                  this.costTypeList_N_last_N.forEach(val => {
                    // alert(item.type);
                    if (val.id == item.type) {
                      this.$set(item, "typeName", val.name);
                    }
                  });
                }
              }
            } else {
              this.costTypeList.forEach(val => {
                if (val.id == item.type) {
                  let startT = item.cycleStartDateLg
                    ? moment(item.cycleStartDateLg).format("YYYY-MM-DD")
                    : "";
                  let endT = item.cycleEndDateLg
                    ? moment(item.cycleEndDateLg).format("YYYY-MM-DD")
                    : "";
                  let cT = startT
                    ? endT
                      ? "(" + startT + "-" + endT + ")"
                      : ""
                    : "";
                  this.$set(item, "typeName", val.name + cT);
                }
              });
            }
            this.$set(
              item,
              "createTime",
              item.createTimeLg
                ? moment(item.createTimeLg).format("YYYY-MM-DD")
                : ""
            );
          });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.paginationBail = pagination;
          this.data = res.data.rows;
        }
      });
    },
    changePageSizeBail(val) {
      this.billCostList(val.current, val.pageSize);
    },
    //确认到账
    showConfirm(val) {
      this.record = val;
      this.confirmvisible = true;
    },
    handleconfirm() {
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmArrive(values);
          // setTimeout(() => {
          // }, 1500);
          // this.selectList();
        }
      });
    },
    confirmArrive(values) {
      axios({
        url:   this.$baseUrl + "bill/pay/confirmPayment",
        method: "POST",
        withCredentials: true,
        data: {
          ...values,
          paymentId: this.record.paymentId
        }
      }).then(res => {
        if (res.data.success) {
          this.confirmvisible = false;
          this.confirmLoading = false;
          this.payTenantList();
          this.$message.success("已确认到账！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    handleCancel(val) {
      val.previewpayPoofImgList = false;
      this.confirmvisible = false;
    },
    //查看凭证
    handleDetail() {},
    //新增费用
    addBill() {}
  }
};
</script>

<style scoped>

</style>
